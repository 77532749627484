import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import podNames from 'ember-component-css/pod-names';
import { tracked } from '@glimmer/tracking';
export default class HamburgerMenuComponent extends Component {
    @service menu;
    @service intl;
    @service router;
    @tracked activeMenu;

    constructor() {
        super(...arguments);
        this.mobileMenu = this.menu.getMenus();
    }

    get styleNamespace() {
        return podNames['hamburger-menu'];
    }

    @action
    closeMenu() {
        this.args.closeHamburgerMenu();
    }

    @action
    transitionAndClose(route) {
        // transition to the new route and close the hamburger menu
        if (route[1]) {
            this.router.transitionTo(route[0], route[1]);
        } else {
            this.router.transitionTo(route[0]);
        }
        this.args.closeHamburgerMenu();
    }
}
