import Service from '@ember/service';
export default class GeolocationService extends Service {
    currentPosition = null;
    watchId = null;
    callbacks = [];

    constructor() {
        super(...arguments);
    }

    getCurrentPosition(successCallback, errorCallback, options = {}) {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(successCallback, errorCallback, options);
        } else {
            const error = {
                code: 0, // Custom error code
                message: 'Geolocation is not supported by this browser.',
                PERMISSION_DENIED: 1,
                POSITION_UNAVAILABLE: 2,
                TIMEOUT: 3,
            };
            if (typeof errorCallback === 'function') {
                errorCallback(error);
            }
        }
    }

    watchPosition(callback, errorCallback, options = {}) {
        this.callbacks.push(callback);
        if (!this.watchId && navigator.geolocation) {
            this.watchId = navigator.geolocation.watchPosition(
                (position) => {
                    this.currentPosition = position;
                    this.callbacks.forEach((cb) => cb(position));
                },
                (error) => {
                    errorCallback(error);
                },
                options, // Pass the options here
            );
        }
    }
}
