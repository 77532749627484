import Service, { inject as service } from '@ember/service';
import { computed, action } from '@ember/object';
import { A } from '@ember/array';
import config from '../config';
import { singularize, pluralize } from 'ember-inflector';
import { tracked } from '@glimmer/tracking';

export default class MenuService extends Service {
    @service('collector-service') Collector;
    @service session;
    @service intl;
    @service localStorageWrapper;
    @service flags;
    @service router;
    @tracked path;
    @tracked visibleItems = [];
    @tracked hiddenItems = [];
    @tracked moreIsActive = false;
    @tracked paddingPlusMarginForItem = 0;

    containerWidth = 0;

    // Desired order for the menu items for diffrerent brands
    // the rest of the items will be appended to the end of the menu as they are in the config.staticMenus
    ottaMenuOrder = [
        'dashboard',
        'worktime',
        'my-sites',
        'users',
        'absence',
        'projects',
        'resource-planning',
        'own-report',
        'material',
        'drivinglog',
        'messages',
        'settings',
    ];
    moveMenuOrder = [
        'dashboard',
        'worktime',
        'presence',
        'my-sites',
        'projects',
        'users',
        'diary',
        'resource-planning',
        'absence',
        'site-safety',
        'orientation',
        'worktask',
        'own-report',
        'taxman',
        'salary',
        'material',
        'employers',
        'drivinglog',
        'messages',
        'settings',
    ];

    constructor() {
        super(...arguments);
        this.path = this.router.currentRouteName.split('.');
        this.router.on('routeDidChange', this.regenerateMenu);
    }

    // can be called from the outside to regenerate the menu
    // for example when screen size changes
    regenerateMenu = () => {
        this.path = this.router.currentRouteName.split('.');
        this.getMenus();
    };

    // returns the visible and hidden items based on the current container width
    @action
    getMenus() {
        let visibleItems = [];
        let hiddenItems = [];
        let elementSizeWithWithPaddingAndMargin = 0;
        let restToHidden = false;
        let menuObject = this.getMenuObject();
        if (this.containerWidth) {
            let menuWidth = 0;
            // we calculate the width of the "more" button here manually
            const moreWidth = this.getTextWidth(
                this.intl.t('report.more_button'),
                config.brand === 'otta' ? '16px OttaRaisonne' : '16px Open sans',
            );
            // go through the menu items and calculate the width of the menu
            for (let item of menuObject) {
                // try to calculate the width of the item
                elementSizeWithWithPaddingAndMargin = item.width + this.paddingPlusMarginForItem;
                if (
                    menuWidth + elementSizeWithWithPaddingAndMargin + moreWidth <=
                        this.containerWidth &&
                    !restToHidden
                ) {
                    menuWidth += elementSizeWithWithPaddingAndMargin;
                    visibleItems.push(item);
                    if (item.active) {
                        this.moreIsActive = false;
                    }
                } else {
                    restToHidden = true;
                    if (item.active) {
                        this.moreIsActive = true;
                    }
                    hiddenItems.push(item);
                }
            }
        }
        return { visibleItems: visibleItems, hiddenItems: hiddenItems, allItems: menuObject };
    }

    // used for getting static menu-items (tems that are common for all the customers)
    @computed
    get staticMenu() {
        let staticMenuItems = [];
        let ref = config.staticMenus;

        // Desired order for the new menu
        const newMenuOrder = config.brand === 'otta' ? this.ottaMenuOrder : this.moveMenuOrder;

        // Reorder based on the new menu order
        let reorderedItems = [];

        // Add items that are explicitly ordered
        newMenuOrder.forEach((orderId) => {
            // use filter to get the item(s) with the correct id
            let menuItems = ref.filter((item) => item.id === orderId);
            if (menuItems.length > 0) {
                reorderedItems.push(...menuItems);
            }
        });

        // Append the rest of the items (those not explicitly ordered)
        ref.forEach((item) => {
            if (!newMenuOrder.includes(item.id)) {
                reorderedItems.push(item);
            }
        });
        staticMenuItems = reorderedItems;
        return staticMenuItems;
    }
    translate(menus) {
        let form, name, split;
        return menus.map((item) => {
            if (typeof item.text !== 'string') {
                return item;
            }
            split = item.text.split('.');
            form = false;
            name = split[1];
            if (typeof name === 'string') {
                if (name.startsWith('add_')) {
                    name = name.slice(4);
                }
                if (this.Collector.formExists(name)) {
                    form = this.Collector.forms()[name];
                } else if (this.Collector.formExists(singularize(name))) {
                    form = this.Collector.forms()[singularize(name)];
                }
            }
            item.name = item.text;
            if (form && form.name) {
                item.translated = form.name;
            } else {
                item.translated = this.intl.t(item.text);
            }
            return item;
        });
    }

    // generates the menu object from the static menus and the user forms
    getMenuObject() {
        let collector = this.Collector;
        let menus = A(this.translate(this.staticMenu).concat(this.getOwnForms()));
        let active = this.active_menu;
        menus = menus.map((item) => {
            let current = item.link[0].split('.')[0];
            if (current === 'site_safety') {
                current = item.link.length === 2 ? item.link[1] : item.link[0].split('.')[1];
            }
            if (current === 'forms') {
                current = item.link[1];
            }
            item.active = active === current;
            item.width = Math.floor(
                this.getTextWidth(
                    item.translated,
                    config.brand === 'otta' ? '16px OttaRaisonne' : '16px Open sans',
                ),
            );
            return item;
        });
        let ret = collector.needsOfArray(menus.map((item) => Object.assign({}, item)));

        return ret;
    }

    // returns the active menu based on the current route
    get active_menu() {
        let custom, split, toppath;
        if (!this.path) {
            return '';
        }
        toppath = this.path[0];
        if (location.href.includes('custom')) {
            custom = location.href.split('/').filter((item) => {
                return item.includes('custom');
            });
            return custom[0];
        }
        if (toppath === 'workday') {
            return 'worktimes';
        }
        if (toppath === 'site_safety') {
            return this.path[1];
        }
        if (toppath === 'forms') {
            split = location.href.split('/');
            return split[split.indexOf('forms') + 1];
        } else {
            return toppath;
        }
    }

    // Get the width of a text in a specific font
    getTextWidth(text, font) {
        // Create a temporary element
        const canvas = document.createElement('canvas');
        const context = canvas.getContext('2d');

        // Set the font style to match the target element
        context.font = font;

        // Measure the width of the text
        const metrics = context.measureText(text);
        return metrics.width;
    }

    // fetches the customer own forms
    getOwnForms(asAddingLinks) {
        let ret = [];
        let forms = this.Collector.forms();
        // map staticMenu to mappedMenu so it can be used in includes
        const mappedMenu = this.staticMenu.map((item) => (item.link?.[0] ? item.link[0] : item.id));

        Object.keys(forms).forEach((key) => {
            let item = forms[key];
            let name = this.parseMeasurementName(key, item);

            if (item.privileges && item.privileges.access !== true) {
                return; // Skip rest of the loop if access is not true.
            }

            // Skip if item is already included based on staticMenu
            if (mappedMenu.includes(pluralize(key)) || mappedMenu.includes(key)) {
                return;
            }

            if (name) {
                let short_key = key.substring(12).toLowerCase();

                let menuItem = {
                    needs: [`${key}@add`],
                    text: name,
                    translated: name,
                    icon: 'move move-Length',
                    id: key,
                };

                if (asAddingLinks) {
                    menuItem.link = ['site_safety.measurement.add', 'add', short_key];
                } else {
                    menuItem.link = ['site_safety.measurement.graphs', short_key];
                    menuItem.submenus = [
                        { text: 'own_measurement.graphs', link: 'site_safety.measurement.graphs' },
                        { text: 'own_measurement.data', link: 'site_safety.measurement.data' },
                        {
                            text: 'own_measurement.notices',
                            link: 'site_safety.measurement.notices',
                        },
                    ];
                }

                ret.push(menuItem);
            } else if (item.name) {
                let menuItem = {
                    text: item.name.toLowerCase(),
                    translated: item.name,
                    icon: 'move move-CopyRow',
                    id: key,
                };
                if (asAddingLinks) {
                    menuItem.link = ['forms.form-add', key];
                    menuItem.needs = [`${key}@add`]; // needs should be only for adding links
                } else {
                    menuItem.link = ['forms.form-report', key];
                }

                ret.push(menuItem);
            }
        });
        return ret;
    }

    parseMeasurementName(key, item) {
        if (key.substring(0, 11) !== 'measurement') {
            return;
        }
        let name = key.substring(12).toLowerCase();
        if (item.name != null) {
            name = item.name;
        }
        return name;
    }
}
