/* eslint-disable ember/named-functions-in-promises */
import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';
import { get } from '@ember/object';
import RSVP from 'rsvp';
import { isNone } from '@ember/utils';

export default Route.extend({
    store: service(),
    collector: service('collector-service'),
    capi: service(),
    ttapi: service(),

    // Defining query parameters and specifying that the model should refresh when they change
    queryParams: {
        startdate: { refreshModel: true },
        user: { refreshModel: true },
        page: { refreshModel: true },
        group: { refreshModel: true },
        employer: { refreshModel: true },
        superior: { refreshModel: true },
        worktimegroup: { refreshModel: true },
    },

    // The main model hook that fetches data based on the route's parameters
    model(params) {
        const c = get(this, 'collector');

        // Building user parameters based on query parameters
        const userParams = { offset: params.page === 1 ? 0 : 25 * (params.page - 1) };
        Object.keys(params).forEach((item) => {
            if (item === 'user' && params.user) {
                // If 'user' parameter is present, set the 'id' in userParams
                userParams.id = params.user;
            } else if (c.testNeedsOne('user.' + item)) {
                // If the item is needed, add it to userParams
                userParams[item] = params[item];
            }
        });

        // Merging default parameters with user-provided parameters
        const allParams = Object.assign({ limit: 25, order: 'lastname' }, userParams);

        // Constructing the query string for the API request
        let queryString = '';
        Object.entries(allParams).forEach((param) => {
            if (isNone(param[1])) return;

            if (!queryString) queryString += `?${param[0]}=${param[1]}`;
            else queryString += `&${param[0]}=${param[1]}`;
        });

        // Checking user permissions to determine which calendar to show
        const canSeeOnlyOwnAbsences = this.collector.testNeeds(['abcense.user@hide']);
        const showTeamCalendar =
            canSeeOnlyOwnAbsences && this.collector.testNeeds(['products.team_calendar']);

        // If the user can only see their own absences but has access to the team calendar
        if (showTeamCalendar) {
            return RSVP.hash({
                showTeamCalendar: true,
                data: this.fetchTeamAbsences(params),
            });
        }

        // Fetching users from the API
        let userPromise = this.capi.request('abcenses/user' + queryString);

        // Fetch absences in batches
        return RSVP.hash({
            showTeamCalendar: false,
            users: userPromise,
            absences: this.fetchAbsences(userPromise, params),
        });
    },

    // Method to fetch team absences from TTapi
    fetchTeamAbsences(params) {
        return this.ttapi.request(`absence/team?startdate_enddate=${params.startdate}`);
    },

      // Fetch absences for all users in batches of 100 records
      fetchAbsences(usersPromise, params) {
        return usersPromise.then((users) => {
          let allAbsences = [];
          let offset = 0;
          const limit = 100;
          const userIds = users.user.mapBy('id').join(',');

          // Start fetching batches
          return this.fetchBatch(allAbsences, userIds, params.startdate, limit, offset);
        });
      },

      fetchBatch(allAbsences, userIds, startdate, limit, offset) {
        return get(this, 'store')
          .query('abcense', {
            user: userIds,
            startdate_enddate: startdate,
            order: 'startdate',
            sideload: true,
            limit: limit,
            offset: offset,
          })
          .then((batch) => {
            allAbsences.pushObjects(batch.toArray());
            if (get(batch, 'length') === limit) {
              offset += limit;
              // Recursively fetch the next batch
              return this.fetchBatch(allAbsences, userIds, startdate, limit, offset);
            } else {
              // Return all collected absences
              return allAbsences;
            }
          });
      },
});
