import { Promise as EmberPromise } from 'rsvp';

const imageTypes = ['image/gif', 'image/png', 'image/jpg', 'image/jpeg'];
const downscaleFactor = 0.6; //default amount to shrink image by.. only if width param is not given

/**
 * Returns a Blob from the given URL
 * @param {string} url - url to fetch file from
 * @param {boolean} resize - whether to resize received image or not
 * @returns {object} - {name: "file", "file": dataUrl}
 */
export function getImageFromUrl(url, responseType = 'blob') {
    if (!url)
        throw new Error(
            'Fetching image from url failed because it did not receive the url parameter',
        );
    return new EmberPromise((resolve, reject) => {
        var xhr = new XMLHttpRequest();
        xhr.open('GET', url, true);

        xhr.responseType = responseType;

        xhr.onload = async () => {
            let blob = xhr.response;
            blob.lastModifiedDate = new Date();
            blob.name = 'file';

            if (!imageTypes.includes(blob.type))
                throw new Error(
                    'Image of type ' + blob.type + ' received from ' + url + ' is not allowed',
                );

            resolve(blob);
        };

        xhr.onerror = (error) => {
            reject(error);
        };

        xhr.send();
    });
}

/**
 * Returns a resized dataUrl from the given File OR Blob
 * @param {file || blob || string} file - Original file as File, Blob or DataURL.
 * @param {int} width - Target image width
 * @param {int} height - Target image height
 * @param {int} quality - Image compression as % 0-100 or decimal 0.0 - 1.0 default 50 (0.5)
 * @param {boolean} keepAspectRatio - Maintain image's aspect ratio on resize - default True
 * @returns {string} - resized dataUrl of the given file
 */
export function resizeImage(
    file,
    width = null,
    height = null,
    quality = 80,
    keepAspectRatio = true,
) {
    return new EmberPromise((resolve, reject) => {
        // if(!width) throw new Error("Image resize failed because it did not receive the width parameter")
        if (!keepAspectRatio && !height)
            throw new Error(
                'Image resize failed because it wanted to change the aspect ratio, but it did not receive the height parameter',
            );

        const mime = file.type || 'image/jpeg';

        //we want quality to be decimal (like 0.5 for 50%)
        if (quality % 1 === 0) quality = (quality / 100).toFixed(2);

        const reader = new FileReader();

        if (typeof file === 'string') {
            file = dataURLtoBlob(file);
        }

        reader.readAsDataURL(file);

        reader.onloadend = () => {
            const img = new Image();
            img.src = reader.result;
            img.onload = () => {
                const canvas = document.createElement('canvas');
                let finalQuality = quality;
                let finalDownscaleFactor = downscaleFactor;

                // If image is smaller than 1600px in any dimension, we do not downscale it
                if (img?.width < 1600 || img?.height < 1600) {
                    finalQuality = 100;
                    finalDownscaleFactor = 1;
                }

                //if we do not have a target width just downscale image by some amount
                if (!width) width = img.width * finalDownscaleFactor;
                //we calculate scaleFactor from image width to maintain aspect ratio
                const scaleFactor = width / img.width;

                if (keepAspectRatio) height = img.height * scaleFactor;

                canvas.width = width;
                canvas.height = height;
                const ctx = canvas.getContext('2d');

                ctx.drawImage(img, 0, 0, width, height);
                //resolve canvas as dataURL
                resolve(canvas.toDataURL(mime, finalQuality));
            };
        };

        reader.onerror = (err) => {
            reject(err);
        };
    });
}

/**
 * @param {string} dataurl - base64
 * @param {string} filename - wanted filename
 */
export function dataURLtoBlob(dataurl, filename = 'file') {
    var arr = dataurl.split(','),
        mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]),
        n = bstr.length,
        u8arr = new Uint8Array(n);
    while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
    }
    let blob = new Blob([u8arr], { type: mime });
    blob.name = filename;
    blob.lastModifiedDate = new Date();
    return blob;
}

export default { getImageFromUrl, resizeImage, dataURLtoBlob };
